import React from 'react';
import Layout from '../../components/layout';

const FreieStellen = ({ data, location, ...props }) => {
	return (
		<Layout location={location} title="Freie Stellen" noSlider {...props}>
			<p>
				Für unsere dermatologische Praxis wünschen wir uns eine
				fachliche, einsatzfreudige Unterstützung für vorerst bis 20
				Wochenstunden, später ggf. mehr.
				<br />
				<br />
				<strong>
					Bewerbungen bitte mit Foto an:
					<br />
					<a href="mailto:info@krokowski-dermatologie.de">
						info@krokowski-dermatologie.de
					</a>
				</strong>
			</p>
		</Layout>
	);
};

export default FreieStellen;
